
















































import { computed, defineComponent, reactive, toRefs, watch } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import BaseTabs from '@/components/utils/BaseTabs.vue'
import Coupons from '@/components/Coupons.vue'
import InputCouponNumber from '@/components/InputCouponNumber.vue'
import { filteredCardTypes, sendToApp } from '@/helpers'
import { useI18n } from 'vue-composable'
import { IS_LOGGING } from '@/data/constants'

import type { ICoupon } from '@/types'

interface State {
  [key: string]: any;
}

export default defineComponent({
  components: {
    BaseTabs,
    Coupons,
    InputCouponNumber
  },
  setup () {
    const { $t } = useI18n()

    const { $route } = router.app
    const $router = router
    const {
      state: {
        coupon
      },
      getters,
      commit,
      dispatch
    } = store

    const state = reactive({
      $t,
      labels: getters['coupon/labels'],
      currentTabIndex: computed(() => coupon.currentTabIndex.type),
      cards: [],
      filteredCards: computed(() => {
        return (getters['coupon/couponsByCountry'] as ICoupon[]).filter(({ settlement_type }) => filteredCardTypes(state.currentTabIndex).includes(settlement_type))
      }),
      sourceCurrencyId: computed(() => $route.query.currency || getters['country/$currentSourceCurrencyId'])
    }) as State

    const flipCard = (id: string) => {
      const cards = coupon.couponBooks.map((card: ICoupon) => Object.assign({}, card, { flipped: id === card.id ? !card.flipped : card.flipped }))
      commit('$SET_STATE', { path: 'coupon.couponBooks', value: cards })
    }

    const setCurrencyId = () => {
      $router.replace({ path: $route.path, query: Object.assign({}, $route.query, { currency: state.sourceCurrencyId }) })
    }

    const getCouponCards = async (toCurrencyId: string | (string | null)[]) => {
      const changableCurrencyId = !$route.query.currency || (toCurrencyId !== $route.query.currency)
      changableCurrencyId && setCurrencyId()
      await dispatch('coupon/refreshMyCoupons')
      await dispatch('coupon/refreshCouponBook')
    }

    const changeTabs = (index: number) => {
      commit('$SET_STATE', { path: 'coupon.currentTabIndex.type', value: index })
    }

    const useAmplitudeLogging = () => {
      sendToApp('view_couponBook', { sending_country: getters['country/$currentSourceCountryCode'] }, IS_LOGGING)
    }

    useAmplitudeLogging()

    watch(
      () => $route.query.currency,
      (toCurrencyId) => {
        getCouponCards(toCurrencyId)
      },
      { immediate: true }
    )
    return {
      ...toRefs(state),
      flipCard,
      changeTabs,
      sendToApp
    }
  }
})
